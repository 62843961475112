<template>
    <v-dialog v-model="isOpen" width="50%">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Planifier un rendez-vous
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="mt-2">
                <div class="d-flex align-center">
                    <p class="mr-4 mb-0">
                        Rendez-vous <b> {{ appointment.subject }} </b>
                        d'une durée de <b> {{ appointment.duration | minutesToHours() }} </b>
                        <template v-if="isMinAndMaxDateTheSame">
                            à fixer le <b> {{ appointment.minDate | toDate() }} </b>
                        </template>
                        <template v-else-if="appointment.minDate && appointment.maxDate">
                            à fixer entre le <b> {{ appointment.minDate | toDate() }} </b> et le <b> {{ appointment.maxDate | toDate() }} </b>
                        </template>
                        <template v-else-if="appointment.minDate">
                            à fixer a partir du <b> {{ appointment.minDate | toDate() }} </b>
                        </template>
                        <template v-else-if="appointment.maxDate">
                            à fixer avant le <b> {{ appointment.maxDate | toDate() }} </b>
                        </template>
                    </p>

                    <v-select v-model="selectedStakeholder" :items="stakeholders" item-text="fullname" item-value="id" class="flex-grow-0" :menu-props="{ bottom: true, offsetY: true }" hide-details outlined dense />
                </div>

                <v-expansion-panels class="mt-4">
                    <v-expansion-panel v-for="(disponibility, i) of displayDisponibilities" :key="i">
                        <v-expansion-panel-header>
                            <span class="text-uppercase text-caption font-weight-bold">
                                {{ disponibility.startDate | toDay() }} {{ disponibility.startDate | toDayNumber() }} {{ disponibility.startDate | toMonth() }}
                            </span>

                            {{ disponibility.startDate | toTime(false) }} - {{ disponibility.endDate | toTime(false) }}
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                            <CreateAppointmentInstanceValidationDialog v-for="(hour, i) of disponibility.hours" :key="i" :disponibility="disponibility" :startDate="hour" @created="$emit('created')">
                                <template v-slot:activator="{ on }">
                                    <v-btn color="blue" class="mr-4 mb-4" dark v-on="on">
                                        {{ hour | toTime(false) }}
                                    </v-btn>
                                </template>
                            </CreateAppointmentInstanceValidationDialog>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>

            <v-divider />

            <v-card-actions>

                <v-spacer />

                <v-btn @click="isOpen = false" depressed small>
                    Annuler
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { isSameDay } from 'date-fns';
import appointmentsMixin from '../../../mixins/appointments.js';
import CreateAppointmentInstanceValidationDialog from './CreateAppointmentInstanceValidationDialog.vue';

export default {
    name: 'CreateAppointmentInstanceDialog',

    mixins: [appointmentsMixin],

    props: {
        appointment: { type: Object, required: true }
    },

    components: {
        CreateAppointmentInstanceValidationDialog
    },

    data: () => ({
        isOpen: false,
        disponibilities: [],

        selectedStakeholder: null
    }),

    computed: {
        isMinAndMaxDateTheSame() {
            return isSameDay(new Date(this.appointment.minDate), new Date(this.appointment.maxDate));
        },
        
        displayDisponibilities() {
            let disponibilities = [];

            for (const disponibility of this.disponibilities) {
                disponibility.hours = this.getDisponibilityHours(disponibility);
                const disponibilityTheSameDay = disponibilities.find((d) => {
                    return disponibility.stakeholder.id === d.stakeholder.id && isSameDay(new Date(d.startDate), new Date(disponibility.startDate));
                });
                if (disponibilityTheSameDay) {
                    disponibilityTheSameDay.hours = [...disponibilityTheSameDay.hours, ...disponibility.hours];
                    disponibilityTheSameDay.endDate = disponibility.endDate;
                } else {
                    disponibilities.push(disponibility);
                }
            }

            disponibilities = disponibilities.filter((d) => d.hours.length > 0 && (!this.selectedStakeholder || this.selectedStakeholder === d.stakeholder.id));

            return disponibilities;
        },

        stakeholders() {
            const stakeholders = [];

            for (const disponibility of this.disponibilities) {
                const existing = stakeholders.find((s) => s.id === disponibility.stakeholder.id);
                if (!existing) {
                    stakeholders.push(disponibility.stakeholder);
                }
            }

            return stakeholders;
        }
    },

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.getDisponibilities();
            }
        },

        displayDisponibilities() {
            if (!this.selectedStakeholder && this.displayDisponibilities.length > 0) {
                this.selectedStakeholder = this.displayDisponibilities[0].stakeholder.id;
            }
        }
    },

    methods: {
        async getDisponibilities() {
            try {
                this.setLoading(true);
                const query = {
                    include: 'instances'
                };
                const { disponibilities } = await this.repos.appointments.getAppointmentsDisponibilities(this.appointment.id, query);

                this.disponibilities = disponibilities;
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la récupération des disponibilités',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    }
};
</script>
